import axios from '@/axios';
import { changeCurrency } from '@/helpers/currency';
import { countryCurrency } from '@/helpers/enums';

export default {
	namespaced: true,

	state: {
		user: null,
		self: null,
	},

	actions: {
		login({ commit }, { userName, password }) {
			return axios.post('/authentication', { userName, password }).then(({ data }) => {
				// localStorage.setItem('auth', JSON.stringify({ userName, password }));
				commit('LOGIN', data);
				return data;
			});
		},
		loginWithCode({ commit }, { code }) {
			return axios.post('/authentication', null, { params: { code } }).then(({ data }) => {
				// localStorage.setItem('auth', JSON.stringify({ userName, password }));
				commit('LOGIN', data);
				return data;
			});
		},
		signup(_, { userName, phone, email, password, country }) {
			return axios.post('/register', {
				userName,
				phone,
				email,
				password,
				passwordConfirmation: password,
				fullName: userName,
				address: country
			});
		},
		logout({ commit }) {
			commit('LOGOUT');
		},

		registerWithGoogle() {
			return axios.get('/authentication/google/redirect').then((res) => {
				return res.data;
			});
		},
		loginWithGoogle({ dispatch }, { loginCode }) {
			return axios.get(`/auth/google/callback`, { params: { code: loginCode } }).then((res) => {
				dispatch('login', { user: res.data });
				return res.data;
			});
		},

		sendEmailOTP(_, { email }) {
			return axios.post('/authentication/resend-email-verification', {
				userName: email,
				code: true
			});
		},

		registerWithPhone(_, { mobile, code }) {
			return axios
				.get('/authentication/message', { params: { mobile, refCode: code } })
				.then(({ data }) => data);
		},
		checkCode({ commit }, { code, mobile, email }) {
			return axios
				.get('/authentication/message/check-code', { params: { code, number: mobile, email } })
				.then(({ data }) => {
					commit('LOGIN', data);
					return data;
				});
		},
		sendPersonalInfo(_, { userName, email, country, userId }) {
			return axios.post('/users/' + userId, {
				email,
				fullName: userName,
				address: country,
				_method: 'PUT',
			});
		},

		forgetPassword(_, { email }) {
			return axios.post('/authentication/password/email', { email: email }).then((res) => {
				return res.data;
			});
		},
		sendCode(_, { code }) {
			return axios.post('/authentication/password/code/check', { token: code }).then((res) => {
				return res.data;
			});
		},
		resetPassword(_, { token, password, passwordConfirmation }) {
			return axios
				.post('/authentication/password/reset', {
					token,
					password,
					passwordConfirmation,
				})
				.then((res) => {
					return res.data;
				});
		},

		self({ commit }) {
			return axios.get('/users/self').then(({ data }) => {
				commit('SELF', data);
				return data;
			});
		},
	},

	mutations: {
		setUserFromLS(state) {
			const user = localStorage.getItem('auth2');
			if (user && JSON.parse(user)) {
				state.user = JSON.parse(user);
			}
		},
		LOGIN(state, user) {
			localStorage.setItem('auth2', JSON.stringify(user));
			state.user = user;
		},
		LOGOUT(state) {
			localStorage.removeItem('auth2');
			state.user = null;
			state.self = null;
			changeCurrency('KWD');
		},
		SELF(state, self) {
			state.self = self;
			const currency = countryCurrency.find(c => c.country === self.address)?.currency;
			changeCurrency(currency || 'KWD');
		},
	},
};

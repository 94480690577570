import axios from 'axios';

// export const BASE_URL = 'https://api.sanadagency.com/api/';
export const BASE_URL = 'https://mirror-api.sanadagency.com/api/';

export default axios.create({
	baseURL: BASE_URL,
	headers: {
		'accept-language': 'ar',
	},
});

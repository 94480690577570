<template>
	<div class="white-bg">
		<main-nav
			v-if="!['valid'].includes($route.name)"
			:loading-categories="loadingCategories"></main-nav>
		<router-view
			:key="$route.fullPath"
			:loading-categories="loadingCategories"
			:loading-products="loadingProducts"
			:loading-cart="loadingCart"
			:loading-filters="loadingFilters"
			@fetch-cart="fetchCart"
			@fetch-profile="fetchProfile" />
		<main-footer v-if="!['valid'].includes($route.name)"></main-footer>

		<add-to-cart-dialog
			v-if="addToCartProduct"
			:product="addToCartProduct"
			:is-edit="editInCart"
			@close="addToCartProduct = null"></add-to-cart-dialog>

		<add-other-product-dialog
			v-if="addOtherProduct"
			:product="addOtherProduct"
			:price="addOtherPrice"
			@close="addOtherProduct = null"></add-other-product-dialog>

		<b-snackbar></b-snackbar>
	</div>
</template>

<script>
import MainFooter from './components/MainFooter.vue';
import MainNav from './components/MainNav.vue';
import BSnackbar from './components/BSnackbar.vue';
import AddToCartDialog from './components/AddToCartDialog.vue';
import AddOtherProductDialog from './components/AddOtherProductDialog.vue';
import axios from '@/axios';
import { purchaseAfterThreeWeek, purchaseAfterWeek, purchaseAfterTwoWeek } from '@/emailAxios/emails';

export default {
	components: { MainFooter, MainNav, BSnackbar, AddToCartDialog, AddOtherProductDialog },

	data() {
		return {
			loadingCategories: true,
			loadingProducts: true,
			loadingProfile: false,
			loadingCart: true,
			loadingFilters: true,

			addToCartProduct: null,
			editInCart: false,

			addOtherProduct: null,
			addOtherPrice: null,

			waitingForPay: false,
		};
	},

	methods: {
		fetchFilters() {
			this.loadingFilters = true;
			this.$store.dispatch('filters/fetchAll').finally(() => {
				this.loadingFilters = false;
			});
		},
		fetchCategories() {
			this.loadingCategories = true;
			this.$store.dispatch('categories/fetchAll').finally(() => {
				this.loadingCategories = false;
			});
		},
		fetchProductsAndOffers() {
			this.loadingProducts = true;
			Promise.all([
				this.$store.dispatch('products/fetchAll'),
				this.$store.dispatch('offers/fetchAll'),
			]).finally(() => {
				this.loadingProducts = false;
			});
		},
		fetchProfile({ force = false } = {}) {
			this.loadingProfile = true;
			this.loadingCart = true;
			return this.$store
				.dispatch('auth/self')
				// FIXME // BUG remove comment for Update Currencies
				// .then(() => this.fetchProductsAndOffers())
				.catch(() => {})
				.finally(() => {
					this.fetchCart({ force });
					this.loadingProfile = false;
				});
		},
		fetchCart({ force = false } = {}) {
			this.loadingCart = true;
			this.$store
				.dispatch('cart/fetchCart', { force })
				.catch(() => {})
				.finally(() => {
					this.loadingCart = false;
				});
		},
		fetchCashback() {
			this.$store.dispatch('settings/getCashback');
		},
	},

	async created() {
		this.$eventBus.$on('show-add-to-cart-dialog', (product, isEdit = false) => {
			this.addToCartProduct = product;
			this.editInCart = isEdit;
		});

		this.$eventBus.$on('show-other-product-dialog', (product, price) => {
			this.addOtherProduct = product;
			this.addOtherPrice = price;
		});

		const fetching = async () => {
			this.fetchCategories();
			this.fetchProductsAndOffers();
			this.fetchFilters();
			// this.fetchCashback();
			// this.loadingCart = false;
			this.$store.dispatch('rating/fetchAll')
			await this.fetchProfile();
		};
		await fetching();

		if (this.$route.query.done === 'true') {
			purchaseAfterThreeWeek();
			purchaseAfterWeek();
			purchaseAfterTwoWeek();
			this.$router.replace({ name: 'thank-you' }, () => {});
			setTimeout(() => {
				this.$eventBus.$emit('snackbar', {
					message: 'تم الدفع بنجاح',
					isError: false,
				});
			}, 1000);
		}
		if (this.$route.query.done === 'false') {
			setTimeout(() => {
				this.$eventBus.$emit('snackbar', {
					message: 'فشلت  عملية الدفع',
					isError: true,
				});
			}, 1000);
		}

		// for google login
		if (this.$route.query.code) {
			this.$store
				.dispatch('auth/loginWithGoogle', {
					loginCode: this.$route.query.code,
				})
				.then((data) => {
					this.$eventBus.$emit('snackbar', { message: 'اهلا وسهلا بك', isError: false });
					this.$router.replace({ name: 'home' });
				})
				.catch((e) => {
					this.$eventBus.$emit('snackbar', { message: 'حدث خطأ ما' });
				});
		}

		// for counting the visits
		if (!localStorage.getItem('first-time')) {
			axios.get('/settings').then(() => {
				localStorage.setItem('first-time', 1);
			});
		}
	},
};
</script>

<style lang="scss">
@use '@/style' as *;

@import '@/style/style';
@import '@/style/helpers';
@import '@/style/components';

@import '@fortawesome/fontawesome-free/css/all.css';

body {
	background-color: blue;
}

.loader-container {
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: map($colors, 'sec');
}

.snackbar {
	position: fixed;
	right: 0;
	left: 0;
	margin: auto;
	width: 400px;
	bottom: 3rem;
	padding: 0.5rem 1rem;
	color: white;
	border-radius: 5px;
	box-shadow: map($shadows, 2);
	&.error {
		background-color: map($colors, 'main');
	}
	&.success {
		background-color: green;
	}
}
</style>
